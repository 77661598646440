import styled from 'styled-components';
import theme from 'theme';

import ShidoWithSignIcon from 'assets/icons/ShidoWithSignIcon';

import { LogoMark } from './styledElements';

const Footer = () => (
  <Container>
    <IconWrapper>
      <ShidoWithSignIcon width={48} height={29} color={theme.colors.primary} />
      <LogoMarkContainer colored>®</LogoMarkContainer>
    </IconWrapper>
    <DashedLine />
    <FooterText>© 2022 Shido Group Ltd</FooterText>
  </Container>
);

const Container = styled.nav`
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 58px;
  flex-direction: column;
  align-items: center;
`;

const DashedLine = styled.hr`
  border: none;
  border-top: 3px dashed ${({ theme }) => theme.colors.gray};
  height: 1px;
  width: 50%;
  margin-top: 40px;
`;

const FooterText = styled.p`
  color: ${({ theme }) => theme.colors.primary};
  margin-top: 30.5px;
  font-size: 12px;
  margin-right: 8px;
`;

const IconWrapper = styled.div`
  margin-left: 3px;
  position: relative;
  display: flex;
  align-items: flex-start;
  width: fit-content;
`;

const LogoMarkContainer = styled(LogoMark)`
  font-size: 16px;
  top: -3.5px;
  right: -19px;
`;

export default Footer;
