import { QueryFunctionContext, useQuery } from 'react-query';

type UserValidityResponse = {
  message: string | null;
  roles: string[];
  succeeded: boolean;
  token: string;
  id: string;
  refreshToken: string;
};

const useCheckUserValidity = (
  queryKeys: string[],
  queryFn: (data: QueryFunctionContext) => Promise<UserValidityResponse>
) => useQuery([...queryKeys], queryFn, { retry: 1 });

export default useCheckUserValidity;
