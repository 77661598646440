export default {
  breakpoints: {
    xxs: '320px',
    xs: '375px',
    sm: '426px',
    md: '769px',
    lg: '1025px',
    xl: '1400px',
    xxl: '1920px',
  },
  colors: {
    primary: '#414E72',
    secondary: '#DA7263',
    darkBlue: '#4B5474',
    darkBlueV1: '#67718e',
    darkBlueV2: '#66ABC1',
    darkBlueV3: '#404d73',
    darkRed: '#d4c1c5',
    primRed: '#E6CAD0',
    darkYellow: '#e5e50a',
    lightYellow: '#F7F7F2',
    red: '#FF0000',
    lightRed: '#D82E5A',
    lightRedV1: '#FD8787',
    lightBlue: '#62B0CB',
    lightBlueV1: '#62b1cb',
    black: '#000',
    white: '#fff',
    gray: '#EBEBEB',
    darkGray: '#707070',
    sierraWhite: '#DEDFDA',
    swamp: '#8AB49C',
    funtainBlue: '#65B0C9',
    hibiscus: '#CC3A5F',
    quillGray: '#E2E2E0',
    careysPink: '#D2A4AE',
    cararra: '#EEEFEB',
    dustyGray: '#999999',
    dustyGrayV2: '#AAACB4',
    halfBaked: '#87C0D2',
  },
  fonts: {
    typeface: 'F37Ginger',
  },
};
