import { ReactPlugin } from '@microsoft/applicationinsights-react-js';
import {
  ApplicationInsights,
  ITelemetryPlugin,
} from '@microsoft/applicationinsights-web';

export let reactPlugin: ITelemetryPlugin | ReactPlugin;
export let appInsights: ApplicationInsights;

export const initialise = (instrumentationKey: string) => {
  if (!instrumentationKey) {
    throw new Error(
      'Could not initialise App Insights: `instrumentationKey` not provided'
    );
  }

  reactPlugin = new ReactPlugin();

  appInsights = new ApplicationInsights({
    config: {
      instrumentationKey,
      enableAutoRouteTracking: true,
      extensions: [reactPlugin],
    },
  });

  appInsights.loadAppInsights();
};
