import { useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import theme from 'theme';

import ShidoIcon from 'assets/icons/ShidoIcon';
import Loader from 'components/Loader';

import { AuthContext } from '../context/AuthContext';
import useCheckUserValidity from '../hooks/useCheckUserValidity';
import { checkUserValidity } from '../service/authService';

enum roleType {
  Partner = 'Partner',
}

type ValidProps = {
  email: string;
  password: string;
  setErrorText: (value: string) => void;
  goToFirstStage: (value: string) => void;
};

const Success = ({
  setErrorText,
  goToFirstStage,
  email,
  password,
}: ValidProps) => {
  const navigate = useNavigate();
  const { setTokens } = useContext(AuthContext);
  const { data: userRoles } = useCheckUserValidity([email, password], () => {
    return checkUserValidity(email, password);
  });

  useEffect(() => {
    if (userRoles) {
      if (!userRoles.succeeded) {
        setErrorText('Bad credentials');
        goToFirstStage('sign');

        return;
      }

      if (!userRoles.roles.includes(roleType.Partner)) {
        setErrorText('You dont have permission');
        goToFirstStage('sign');

        return;
      }
      setTokens(userRoles.token, userRoles.refreshToken);
      navigate('/introduction');
    }
  }, [goToFirstStage, navigate, setErrorText, setTokens, userRoles]);

  return (
    <SuccessWrapper>
      <ShidoIcon color={theme.colors.white} />
      <Loader
        circles={[
          {
            color: theme.colors.careysPink,
            speed: 2,
            size: 180,
          },
          {
            color: theme.colors.careysPink,
            speed: 2.3,
            size: 140,
          },
        ]}
      />
    </SuccessWrapper>
  );
};

const SuccessWrapper = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  align-items: center;
  justify-content: center;
`;

export default Success;
