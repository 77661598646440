import { Navigate } from 'react-router-dom';

import useAuth from './hooks/useAuth';
import { RoutesPath } from './models';

type PrivateRouteProps = {
  children: JSX.Element;
};

const PrivateRoute = ({ children }: PrivateRouteProps) => {
  const { isAuthenticated, tokenUpdateComplete } = useAuth();

  return !isAuthenticated && tokenUpdateComplete ? (
    <Navigate to={RoutesPath.PartnerLogin} />
  ) : (
    children
  );
};

export default PrivateRoute;
