const SignOutIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    width="21.829"
    height="23.996"
    viewBox="0 0 21.829 23.996"
  >
    <defs>
      <clipPath id="clip-path">
        <path
          id="Path_6991"
          data-name="Path 6991"
          d="M9.5-13.085a1.5,1.5,0,0,0,1.5,1.5,1.5,1.5,0,0,0,1.5-1.5V-22.5A1.5,1.5,0,0,0,11-24a1.5,1.5,0,0,0-1.5,1.5Z"
          fill="#69b0c7"
        />
      </clipPath>
      <clipPath id="clip-path-2">
        <path
          id="Path_6993"
          data-name="Path 6993"
          d="M.2-12.467A10.917,10.917,0,0,0,6.12-1.159a10.917,10.917,0,0,0,12.6-2.036,10.825,10.825,0,0,0,3.08-9.278A11.021,11.021,0,0,0,16.067-20.6a1.5,1.5,0,0,0-1.539-.01,1.5,1.5,0,0,0-.738,1.35,1.5,1.5,0,0,0,.839,1.29,8.048,8.048,0,0,1,4.2,5.925,7.914,7.914,0,0,1-4.287,8.21A7.914,7.914,0,0,1,5.4-5.316a7.854,7.854,0,0,1-2.237-6.726,8.042,8.042,0,0,1,4.2-5.922,1.5,1.5,0,0,0,.8-1.284,1.5,1.5,0,0,0-.726-1.325,1.5,1.5,0,0,0-1.51-.022A11.015,11.015,0,0,0,.2-12.467Z"
          fill="#69b0c7"
        />
      </clipPath>
    </defs>
    <g id="Group_6303" data-name="Group 6303" transform="translate(-0.089 24)">
      <g id="Group_6301" data-name="Group 6301" clipPath="url(#clip-path)">
        <path
          id="Path_6990"
          data-name="Path 6990"
          d="M4.5-29h13V-6.585H4.5Z"
          fill="#69b0c7"
        />
      </g>
      <g id="Group_6302" data-name="Group 6302" clipPath="url(#clip-path-2)">
        <path
          id="Path_6992"
          data-name="Path 6992"
          d="M-4.911-25.817H26.918V5H-4.911Z"
          fill="#69b0c7"
        />
      </g>
    </g>
  </svg>
);

export default SignOutIcon;
