const DEFAULT_WIDTH = 200;
const DEFAULT_HEIGTH = 59;

type Props = {
  width?: number;
  height?: number;
};

const ShidoLogoIcon = ({
  width = DEFAULT_WIDTH,
  height = DEFAULT_HEIGTH,
}: Props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    viewBox="0 0 200 59.508"
  >
    <g
      id="Group_6055"
      data-name="Group 6055"
      transform="translate(-648.77 -458.552)"
    >
      <g
        id="Group_6053"
        data-name="Group 6053"
        transform="translate(648.77 468.673)"
      >
        <path
          id="Path_6864"
          data-name="Path 6864"
          d="M711.369,483.373a21.446,21.446,0,0,0-30.072,0l-9.866,9.764a7.183,7.183,0,0,0-2.022,6.45,6.909,6.909,0,0,0,1.156,2.76,7.367,7.367,0,0,0,11.205,1.023l6.527-6.459a2.1,2.1,0,0,1,1.278-.621,1.994,1.994,0,0,1,1.588.569,1.957,1.957,0,0,1,0,2.785L681.3,509.41a16.079,16.079,0,0,1-22.546,0,15.681,15.681,0,0,1,0-22.313,16.091,16.091,0,0,1,18.034-3.128.319.319,0,0,0,.359-.061l2.347-2.322c.376-.372.764-.727,1.161-1.07A.316.316,0,0,0,680.6,480a21.467,21.467,0,0,0-25.617,3.372,20.914,20.914,0,0,0,0,29.761,21.447,21.447,0,0,0,30.072,0l9.868-9.765a7.235,7.235,0,0,0-4.238-12.3,7.335,7.335,0,0,0-6.1,2.063l-6.526,6.458a2.1,2.1,0,0,1-1.277.621,1.994,1.994,0,0,1-1.59-.569,1.949,1.949,0,0,1-.575-1.575,2.063,2.063,0,0,1,.628-1.263l9.812-9.711a16.08,16.08,0,0,1,22.546,0,15.681,15.681,0,0,1,0,22.313,16.09,16.09,0,0,1-18.034,3.128.32.32,0,0,0-.359.061l-2.346,2.322q-.562.556-1.16,1.071a.315.315,0,0,0,.046.514,21.473,21.473,0,0,0,25.616-3.372A20.914,20.914,0,0,0,711.369,483.373Z"
          transform="translate(-648.77 -474.901)"
          fill="#fff"
        />
        <g
          id="Group_6052"
          data-name="Group 6052"
          transform="translate(93.09 -10.121)"
        >
          <path
            id="Path_6865"
            data-name="Path 6865"
            d="M946.473,525.9q2.727,0,2.7-1.642,0-.9-1.169-1.481a14.323,14.323,0,0,0-2.819-1,27.364,27.364,0,0,1-3.337-1.064,6.775,6.775,0,0,1-2.842-2.105,5.77,5.77,0,0,1-1.178-3.747,6.755,6.755,0,0,1,2.121-5.146,8.132,8.132,0,0,1,5.81-2,11.838,11.838,0,0,1,8.27,2.818l-3.15,4.14a8.743,8.743,0,0,0-4.937-1.712q-2.429,0-2.429,1.434,0,.879,1.146,1.434a20.015,20.015,0,0,0,2.8,1.065q1.65.509,3.355,1.2a6.752,6.752,0,0,1,2.864,2.221,5.9,5.9,0,0,1,1.184,3.725,6.431,6.431,0,0,1-2.279,5.106q-2.279,1.969-6.767,1.992A13.935,13.935,0,0,1,937.221,528l3.061-4.412A9.22,9.22,0,0,0,946.473,525.9Z"
            transform="translate(-937.221 -495.64)"
            fill="#fff"
          />
          <path
            id="Path_6866"
            data-name="Path 6866"
            d="M1018.651,504.862V492.9q0-5.269-4.625-5.293-4.625,0-4.6,5.2v12.058h-5.8V469.976h5.8v14.153a9.708,9.708,0,0,1,5.891-2.071,9.189,9.189,0,0,1,6.581,2.439q2.576,2.439,2.554,7.455v12.91Z"
            transform="translate(-982.37 -469.976)"
            fill="#fff"
          />
          <path
            id="Path_6867"
            data-name="Path 6867"
            d="M1084.815,481.873a3.561,3.561,0,0,1-2.623-1.059,3.724,3.724,0,0,1,0-5.2,3.6,3.6,0,0,1,2.623-1.035,3.479,3.479,0,0,1,2.6,1.035,3.57,3.57,0,0,1,1.013,2.6,3.647,3.647,0,0,1-1.013,2.623A3.366,3.366,0,0,1,1084.815,481.873Zm-2.922,26.118V485.6h5.8v22.39Z"
            transform="translate(-1035.069 -473.105)"
            fill="#fff"
          />
          <path
            id="Path_6868"
            data-name="Path 6868"
            d="M1130.14,504.862v-1.443a9.566,9.566,0,0,1-6.213,2.064,11.521,11.521,0,0,1-8.422-3.442,11.678,11.678,0,0,1,0-16.577,11.585,11.585,0,0,1,8.422-3.407,9.606,9.606,0,0,1,6.213,2.071V469.976h5.8v34.886Zm-12.4-11.1a6,6,0,0,0,1.818,4.358,5.866,5.866,0,0,0,4.4,1.821,6.05,6.05,0,0,0,4.395-1.821,6.1,6.1,0,0,0-.046-8.691,6.158,6.158,0,0,0-8.745,0A5.926,5.926,0,0,0,1117.737,493.759Z"
            transform="translate(-1056.093 -469.976)"
            fill="#fff"
          />
          <path
            id="Path_6869"
            data-name="Path 6869"
            d="M1208.85,507.721a11.467,11.467,0,0,1,8.437,3.384,11.687,11.687,0,0,1-.046,16.624,12.035,12.035,0,0,1-16.828-.024,11.686,11.686,0,0,1,0-16.577A11.6,11.6,0,0,1,1208.85,507.721Zm-6.2,11.7a6,6,0,0,0,1.817,4.358,5.867,5.867,0,0,0,4.4,1.821,6.052,6.052,0,0,0,4.4-1.821,6.1,6.1,0,0,0-.046-8.692,6.158,6.158,0,0,0-8.745,0A5.926,5.926,0,0,0,1202.649,519.422Z"
            transform="translate(-1113.826 -495.639)"
            fill="#fff"
          />
        </g>
      </g>
      <g
        id="Group_6054"
        data-name="Group 6054"
        transform="translate(717.97 471.06)"
      >
        <path
          id="Path_6870"
          data-name="Path 6870"
          d="M875.744,469.524a5.012,5.012,0,1,1-3.534-1.464A4.83,4.83,0,0,1,875.744,469.524Zm-3.546,7.647a3.882,3.882,0,0,0,2.875-1.2,3.956,3.956,0,0,0,1.188-2.9,4,4,0,0,0-1.188-2.909,4.033,4.033,0,0,0-5.751-.007,4.179,4.179,0,0,0,0,5.812A3.88,3.88,0,0,0,872.2,477.171Zm-1-3.587v2.308h-.952v-5.7h1.738a3.6,3.6,0,0,1,2.051.4,1.422,1.422,0,0,1,.511,1.208,1.61,1.61,0,0,1-1.412,1.7l1.412,2.4h-1.137l-1.361-2.321Zm1.035-.85a2.25,2.25,0,0,0,.984-.173.689.689,0,0,0,.352-.678.674.674,0,0,0-.326-.665,2.279,2.279,0,0,0-1.009-.16h-1.035v1.675Z"
          transform="translate(-867.201 -468.06)"
          fill="#fff"
        />
      </g>
    </g>
  </svg>
);

export default ShidoLogoIcon;
