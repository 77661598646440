import styled from 'styled-components';

type IntroductionListProps = {
  frameLink?: string;
};

const IntroductionList = ({ frameLink }: IntroductionListProps) => {
  return (
    <>
      <GapElement />
      <Wrapper>
        <iframe
          style={{
            position: 'absolute',
            width: '100%',
            overflow: 'hidden',
            height: '110%',
            marginTop: '-55px',
          }}
          src={frameLink}
        />
      </Wrapper>
    </>
  );
};

const Wrapper = styled.div`
  margin-left: -4px;
  overflow: hidden;
  position: relative;
  width: 100%;
  box-shadow: 0 5px 40px rgb(0 0 0 / 20%);
  background-color: ${({ theme }) => theme.colors.white};
  border-radius: 5px;
  height: 540px;
  margin-top: 16px;
  display: flex;
  flex-direction: column;
  align-items: center;
  box-sizing: border-box;
`;

const GapElement = styled.div`
  padding-top: 106px;
`;

export default IntroductionList;
