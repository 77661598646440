import { memo } from 'react';
import styled from 'styled-components';
import theme from 'theme';

import DefaultButton from 'components/buttons/DefaultButton';

type IntroBarProps = {
  title: string;
  name?: string;
  isIntroPageChecked?: boolean;
  openModalWindow: () => void;
};

const IntroBar = ({
  title,
  name,
  isIntroPageChecked,
  openModalWindow,
}: IntroBarProps) => {
  return (
    <Container>
      <Wrapper>
        <h4>{title}:</h4>
        <p>{name}</p>
      </Wrapper>
      {isIntroPageChecked && (
        <DefaultButton
          withShadow
          borderSize="md"
          buttonSize="md"
          text="Add intro"
          isActive
          textColor={theme.colors.white}
          buttonColor={theme.colors.funtainBlue}
          clickHandler={openModalWindow}
        />
      )}
    </Container>
  );
};

const Container = styled.div`
  margin-left: -2px;
  position: absolute;
  top: -40px;
  width: 100%;
  height: 80px;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: ${({ theme }) => theme.colors.white};
  padding: 0 19px;
  box-sizing: border-box;
`;

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  font-size: 18px;
  color: ${({ theme }) => theme.colors.primary};
  font-weight: 400;
  margin-top: 1px;

  h4 {
    line-height: 25px;
    font-size: 20px;
    font-weight: 700;
    padding-right: 5px;
    letter-spacing: 0.2px;
  }

  p {
    line-height: 25px;
    font-size: 20px;
    max-width: 200px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    letter-spacing: -0.6px;
    margin-left: -1px;
  }

  @media (max-width: 880px) {
    p {
      max-width: 90px;
    }
  }
`;

export default memo(IntroBar);
