const ChatIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="60"
    height="60"
    viewBox="0 0 60 60"
  >
    <defs>
      <clipPath id="a">
        <path
          data-name="Path 15275"
          d="M10.25-10.944a2.5 2.5 0 0 1 2.5-2.5H20a.25.25 0 0 0 .25-.25v-6.25a1.5 1.5 0 0 0-1.5-1.5h-17a1.5 1.5 0 0 0-1.5 1.5v10a1.5 1.5 0 0 0 1.5 1.5h1.5v3.5a.5.5 0 0 0 .309.463.5.5 0 0 0 .541-.109l3.857-3.854H10a.25.25 0 0 0 .25-.25Z"
          fill="#fff"
        />
      </clipPath>
      <clipPath id="b">
        <path
          data-name="Path 15277"
          d="M22.75-11.944h-10a1 1 0 0 0-1 1v6a1 1 0 0 0 1 1h4.5l3.668 3.26a.5.5 0 0 0 .537.082.5.5 0 0 0 .3-.455v-2.887h1a1 1 0 0 0 1-1v-6a1 1 0 0 0-1.005-1Z"
          fill="#fff"
        />
      </clipPath>
    </defs>
    <circle data-name="Ellipse 1" cx="30" cy="30" r="30" fill="#424f71" />
    <g data-name="Group 15722">
      <g
        data-name="Group 15720"
        clipPath="url(#a)"
        transform="translate(18 41.001)"
      >
        <path data-name="Path 15274" d="M-4.75-26.444h30v27h-30Z" fill="#fff" />
      </g>
      <g
        data-name="Group 15721"
        clipPath="url(#b)"
        transform="translate(18 41.001)"
      >
        <path
          data-name="Path 15276"
          d="M6.75-16.944h22V4.442h-22Z"
          fill="#fff"
        />
      </g>
    </g>
  </svg>
);

export default ChatIcon;
