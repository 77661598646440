import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import styled from 'styled-components';
import theme from 'theme';
import * as yup from 'yup';

import { yupResolver } from '@hookform/resolvers/yup';
import DefaultButton from 'components/buttons/DefaultButton';
import FormInput from 'components/FormInput';
import FormLabel from 'components/FormLabel';

import { FormContainer, InputContainer } from '../styled/formsStyledElements';

const SIGN_IN_EMAIL_INPUT_ID = 'email';
const TIMEOUT_OFF_ERROR = 3000;

const validationSchema = yup.object().shape({
  email: yup.string().required('This field is required'),
});

type EmailFormProps = {
  goToNextStage: (email: string) => void;
  errorText: string;
};

const EmailForm = ({ goToNextStage, errorText }: EmailFormProps) => {
  const {
    register,
    formState: { isValid },
    handleSubmit,
  } = useForm({
    mode: 'onChange',
    resolver: yupResolver(validationSchema),
  });
  const [active, setActive] = useState(false);
  const [isShowError, setShowError] = useState(false);

  useEffect(() => {
    if (errorText) {
      setShowError(true);

      setTimeout(() => {
        setShowError(false);
      }, TIMEOUT_OFF_ERROR);
    }
  }, [errorText]);

  return (
    <FormContainer
      onSubmit={handleSubmit(({ email }) => {
        goToNextStage(email);
      })}
    >
      <Input>
        <FormLabel
          onFocus={() => setActive(true)}
          htmlFor={SIGN_IN_EMAIL_INPUT_ID}
          active={active}
        >
          Enter your email address
        </FormLabel>

        <FormInput
          id={SIGN_IN_EMAIL_INPUT_ID}
          type="text"
          autoComplete="off"
          {...register(SIGN_IN_EMAIL_INPUT_ID)}
          onFocus={() => setActive(true)}
        />
      </Input>

      {isShowError && <ErrorMessageText>{errorText}</ErrorMessageText>}

      <DefaultButton
        isSubmit
        withShadow
        borderSize="lg"
        buttonSize="lg"
        text="continue"
        isActive={isValid}
        isUppercase
        textColor={theme.colors.white}
        buttonColor={
          isValid ? theme.colors.funtainBlue : theme.colors.quillGray
        }
      />
    </FormContainer>
  );
};

const Input = styled(InputContainer)`
  height: 58px;
  margin-bottom: 108px;

  input {
    padding: 0;
    margin-bottom: 6px;
  }
`;

const ErrorMessageText = styled.p`
  font-size: 14px;
  color: ${({ theme }) => theme.colors.lightRed};
  margin-bottom: 10px;
`;

export default EmailForm;
