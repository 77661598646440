import { QueryClient, QueryClientProvider } from 'react-query';
import { BrowserRouter as Router } from 'react-router-dom';
import { ThemeProvider } from 'styled-components';
import theme from 'theme';

import AppInsightsContextProvider from 'context/AppInsightsContext';
import AuthContextProvider from 'modules/auth/context/AuthContext';
import routes from 'modules/router/routes';
import { GlobalStyle } from 'theme/globalStyles';

const queryClient = new QueryClient();

function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <AppInsightsContextProvider>
        <ThemeProvider theme={theme}>
          <Router>
            <AuthContextProvider>
              <GlobalStyle />
              {routes}
            </AuthContextProvider>
          </Router>
        </ThemeProvider>
      </AppInsightsContextProvider>
    </QueryClientProvider>
  );
}

export default App;
