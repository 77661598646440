import config from 'config';

import { axiosClient } from 'axiosClient/axiosClient';

export const getUserId = async (token?: string) => {
  const { id } = await axiosClient
    .get(`/api/v${config.apiVersion}/Account/UserInfo`, {
      headers: {
        authorization: `Bearer ${token}`,
      },
    })
    .then((response) => response.data.payload);

  return { id };
};

export const getProfileInfo = async ({ token }: { token: string }) => {
  const userInfo = await axiosClient
    .get(`/api/v${config.nextVersion}/Profile/MyProfile`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response) => response.data.payload.profile);
  const { id } = await getUserId(token);
  const { succeeded } = await checkIntroPageInfo(token, id);

  return { ...userInfo, id, isIntroInfoSucceeded: succeeded };
};

export type PartnersLinksDataResponse = {
  partnerLink: {
    id: string;
    userId: string;
    createLink: string;
    readLink: string;
  };
  succeeded: boolean;
};

export const getPartnersDataLinks = async ({
  token,
  userId,
}: {
  token: string;
  userId: string;
}): Promise<PartnersLinksDataResponse> => {
  return axiosClient
    .get(`/api/v${config.apiVersion}/Users/${userId}/Partners/Links`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response) => response.data.payload);
};

type IntroPageInfoResponse = {
  succeeded: boolean;
};

export const checkIntroPageInfo = async (
  token?: string,
  userId?: string
): Promise<IntroPageInfoResponse> => {
  return axiosClient
    .get(`/api/v4/users/${userId}/Partners/IntroPageInfo`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response) => response.data.payload)
    .catch((error) => {
      return error.response.data.payload;
    });
};

export const setCheckedIntroPageInfo = async (
  token?: string,
  userId?: string
): Promise<unknown> => {
  return axiosClient
    .post(
      `/api/v4/users/${userId}/Partners/IntroPageInfo`,
      {},
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    )
    .then((response) => response.data.payload)
    .catch((error) => {
      return error.response.data.payload;
    });
};
