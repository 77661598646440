import { useCallback, useState } from 'react';
import styled from 'styled-components';

import ShidoLogo from 'assets/icons/ShidoLogoIcon';

import EmailForm from '../components/EmailForm';
import Success from '../components/SuccesStage';
import VerifyIdentity from '../components/VerifyIdentity';

const AuthStage = {
  SignIn: 'sign',
  Verify: 'verify',
  Success: 'success',
};

const PartnerLogin = () => {
  const [adminLoginStage, setAdminLoginStage] = useState('sign');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [errorText, setErrorText] = useState('');

  const goToVerifyIdentityStage = useCallback((email: string) => {
    setEmail(email);
    setAdminLoginStage(AuthStage.Verify);
  }, []);

  const goToSuccessStage = useCallback((password: string) => {
    setPassword(password);
    setAdminLoginStage(AuthStage.Success);
  }, []);

  return (
    <Container>
      {adminLoginStage !== AuthStage.Success && (
        <>
          <LogoWrapper>
            <ShidoLogo />
            <LogoWrapperText>partners</LogoWrapperText>
          </LogoWrapper>
          <SignInModal>
            <Header>Sign in</Header>
            {adminLoginStage === AuthStage.SignIn && (
              <EmailForm
                errorText={errorText}
                goToNextStage={goToVerifyIdentityStage}
              />
            )}
            {adminLoginStage === AuthStage.Verify && (
              <VerifyIdentity goToNextStage={goToSuccessStage} />
            )}
          </SignInModal>
        </>
      )}
      {password && adminLoginStage === AuthStage.Success && (
        <Success
          goToFirstStage={setAdminLoginStage}
          setErrorText={setErrorText}
          email={email}
          password={password}
        />
      )}
      <BackgroundImage src="/images/background.svg" />
    </Container>
  );
};

export const LogoWrapper = styled.div`
  position: absolute;
  top: 32px;
  left: 40px;

  @media (max-height: 800px) {
    top: 20px;
    left: 10px;
    transform: scale(0.8);
  }
`;

const LogoWrapperText = styled.div`
  font-size: 20px;
  color: ${({ theme }) => theme.colors.white};
  position: absolute;
  bottom: 5px;
  right: 23px;
  line-height: 24px;
  letter-spacing: 0.8px;
`;

export const Container = styled.div`
  width: 100vw;
  height: 100vh;
  position: relative;
  background: ${({ theme }) => theme.colors.darkBlueV3};
  overflow: hidden;
`;

export const SignInModal = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 600px;
  background: white;
  height: 400px;
  display: flex;
  align-items: center;
  flex-direction: column;
  border-radius: 5px;
  padding: 36px 40px;
  box-sizing: border-box;
  z-index: 10;
`;

export const BackgroundImage = styled.img`
  width: 119%;
  height: auto;
  position: absolute;
  left: 45%;
  top: 76%;
  transform: translate(-50%, -50%);
  z-index: 5;
`;

const Header = styled.div`
  position: relative;
  font-size: 30px;
  font-weight: 700;
  margin-bottom: 75px;
  color: ${({ theme }) => theme.colors.darkBlueV3};
`;

export default PartnerLogin;
