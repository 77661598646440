import { useCallback, useContext, useEffect, useState } from 'react';
import { useMutation } from 'react-query';
import styled from 'styled-components';

import ActionModal from 'components/ActionModal';
import Footer from 'components/Footer';
import Navbar from 'components/navbar/Navbar';
import { AuthContext } from 'modules/auth/context/AuthContext';

import Instruction from '../components/Instruction';
import IntroBar from '../components/IntroBar';
import IntroductionList from '../components/IntroductionList';
import {
  getPartnersDataLinks,
  getProfileInfo,
  PartnersLinksDataResponse,
  setCheckedIntroPageInfo,
} from '../service/IntroductionService';

const Introduction = () => {
  const { accessToken } = useContext(AuthContext);
  const [partnersData, setPartnersData] = useState<PartnersLinksDataResponse>();
  const [firstName, setFirstName] = useState();
  const [isModalShown, setModalShow] = useState(false);
  const [isIntroPageChecked, setIntroPageChecked] = useState(false);

  const togglewModalWindow = useCallback(() => {
    setIntroPageChecked(true);
    setModalShow(!isModalShown);
  }, [isModalShown]);

  const { mutate } = useMutation(getPartnersDataLinks, {
    onSuccess: (data) => {
      setPartnersData(data);
    },
  });

  const { mutate: mutateProfileInfo } = useMutation(getProfileInfo, {
    onSuccess: (data) => {
      setFirstName(data.firstName);
      if (data.id && accessToken) {
        mutate({ token: accessToken, userId: data.id });
      }
      if (data.isIntroInfoSucceeded) {
        setIntroPageChecked(true);
      } else {
        setCheckedIntroPageInfo(accessToken, data.id);
      }
    },
  });

  useEffect(() => {
    if (accessToken) {
      mutateProfileInfo({ token: accessToken });
    }
  }, [accessToken, mutateProfileInfo]);

  return (
    <Container>
      <Navbar />
      <Content>
        <IntroBar
          isIntroPageChecked={isIntroPageChecked}
          openModalWindow={togglewModalWindow}
          name={firstName}
          title="Introductions"
        />

        {isIntroPageChecked ? (
          <IntroductionList frameLink={partnersData?.partnerLink.readLink} />
        ) : (
          <Instruction openModalWindow={togglewModalWindow} />
        )}

        {isModalShown && (
          <ActionModal closeModalWindow={togglewModalWindow}>
            <iframe
              style={{
                width: '100%',
                borderRadius: '5px',
                border: '0',
                backgroundColor: 'transparent',
                boxShadow: '0 5px 40px rgb(0 0 0 / 20%)',
              }}
              src={partnersData?.partnerLink.createLink}
            ></iframe>
          </ActionModal>
        )}

        <Footer />
      </Content>
    </Container>
  );
};

const Content = styled.div`
  position: relative;
  max-width: 100%;
  height: 100%;
  background-color: ${({ theme }) => theme.colors.lightYellow};
  margin: 0 16px 60px 23px;
  box-sizing: border-box;
`;

const Container = styled.div`
  min-height: 100vh;
  overflow-x: hidden;
  background-color: ${({ theme }) => theme.colors.lightYellow};

  &::-webkit-scrollbar {
    width: 0;
  }
`;

export default Introduction;
