import styled from 'styled-components';
import theme from 'theme';

import ShidoWithSignIcon from 'assets/icons/ShidoWithSignIcon';
import { LogoMark } from 'components/styledElements';

import SignOutButton from './SignOutButton';

const Navbar = () => (
  <Container>
    <NavigationContainer>
      <IconWrapper>
        <ShidoWithSignIcon width={64} height={40} color={theme.colors.white} />
        <LogoMarkContainer>®</LogoMarkContainer>
      </IconWrapper>
      <SignOutButton />
    </NavigationContainer>
  </Container>
);

const Container = styled.nav`
  background-color: ${({ theme }) => theme.colors.darkBlue};
  box-sizing: border-box;
  min-height: 119px;
  padding: 2px 40px 20px 40px;
`;

const NavigationContainer = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  height: 80px;
  border-bottom: 1px solid rgba(255, 255, 255, 20%);

  div:first-child {
    margin-right: 12px;
  }
`;

const IconWrapper = styled.div`
  margin-bottom: 3px;
  position: relative;
  display: flex;
  align-items: flex-start;
  width: fit-content;
`;

const LogoMarkContainer = styled(LogoMark)`
  font-size: 14px;
  top: -4.5px;
  right: -13px;
`;

export default Navbar;
