import axios from 'axios';
import Bowser from 'bowser';
import config from 'config';

const browser = Bowser.getParser(window.navigator.userAgent);

export const axiosClient = axios.create({
  baseURL: config.apiUrl,
  responseType: 'json',
  headers: {
    'x-agent': browser.getBrowserName(),
    'x-agent-version': browser.getBrowserVersion(),
  },
});
