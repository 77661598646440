import { useState } from 'react';
import { useForm } from 'react-hook-form';
import styled from 'styled-components';
import theme from 'theme';
import * as yup from 'yup';

import { yupResolver } from '@hookform/resolvers/yup';
import DisplayEyeIcon from 'assets/icons/DisplayEyeIcon';
import HideEyeIcon from 'assets/icons/HideEyeIcon';
import DefaultButton from 'components/buttons/DefaultButton';
import FormInput from 'components/FormInput';
import FormLabel from 'components/FormLabel';

import {
  AsteriskText,
  EyeButton,
  FormContainer,
  InputContainer,
} from '../styled/formsStyledElements';

const SIGN_IN_PASSWORD_INPUT_ID = 'password';

const validationSchema = yup.object().shape({
  password: yup.string().required('This field is required'),
});

type PasswordFormProps = {
  goToNextStage: (password: string) => void;
};

const VerifyIdentity = ({ goToNextStage }: PasswordFormProps) => {
  const {
    register,
    formState: { isValid },
    handleSubmit,
    watch,
  } = useForm({
    mode: 'onChange',
    resolver: yupResolver(validationSchema),
  });
  const passwordValue = watch()[SIGN_IN_PASSWORD_INPUT_ID] || '';
  const [active, setActive] = useState(false);
  const [hidePassword, setHidePassword] = useState(true);
  const [delay, setDelay] = useState(false);

  const togglePasswordDisplay = () => {
    setHidePassword(!hidePassword);
  };

  return (
    <>
      <FormContainer
        onSubmit={handleSubmit(() => goToNextStage(passwordValue))}
      >
        <Input>
          <FormLabel
            setDelay={setDelay}
            onFocus={() => setActive(true)}
            htmlFor={SIGN_IN_PASSWORD_INPUT_ID}
            active={active || passwordValue}
            coloured
          >
            Enter password
          </FormLabel>

          {((passwordValue && delay) || active) && (
            <AsteriskText hidePassword={hidePassword}>
              {'*'.repeat(passwordValue.length)}
            </AsteriskText>
          )}

          <FormInput
            id={SIGN_IN_PASSWORD_INPUT_ID}
            hide={hidePassword}
            autoComplete="off"
            {...register(SIGN_IN_PASSWORD_INPUT_ID)}
            type={hidePassword ? 'password' : 'text'}
            onFocus={() => setActive(true)}
          />

          <EyeButton
            hidePassword={hidePassword}
            onClick={togglePasswordDisplay}
          >
            {hidePassword ? <HideEyeIcon /> : <DisplayEyeIcon />}
          </EyeButton>
        </Input>

        <DefaultButton
          isSubmit
          withShadow
          borderSize="lg"
          buttonSize="lg"
          text="sign in"
          isActive={isValid}
          isUppercase
          textColor={theme.colors.white}
          buttonColor={
            isValid ? theme.colors.funtainBlue : theme.colors.quillGray
          }
        />
      </FormContainer>
    </>
  );
};

const Input = styled(InputContainer)`
  height: 58px;
  margin-bottom: 108px;

  input {
    padding: 0;
    margin-bottom: 6px;
  }
`;

export default VerifyIdentity;
