import styled, { keyframes } from 'styled-components';

import DashedCircleIcon from 'assets/icons/DashedCircleIcon';

type Circle = {
  color: string;
  speed: number;
  size: number;
};

type LoaderProps = {
  circles: Circle[];
};

export const Loader = ({ circles }: LoaderProps) => (
  <>
    {circles.map(({ speed, size, color }) => (
      <AnimationCircle speed={speed} key={size}>
        <DashedCircleIcon size={size} color={color} />
      </AnimationCircle>
    ))}
  </>
);

const rotate = keyframes`
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
`;

const AnimationCircle = styled.div<{ speed: number }>`
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  animation: ${rotate} ${({ speed }) => speed}s linear infinite;
`;

export default Loader;
