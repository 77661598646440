const {
  REACT_APP_API_URL,
  REACT_APP_INSIGHTS_KEY,
  REACT_APP_INSIGHTS_ENDPOINT,
} = process.env;

export default {
  apiVersion: 4,
  nextVersion: 5,
  apiUrl: REACT_APP_API_URL,
  appInsightsKey: REACT_APP_INSIGHTS_KEY,
  appInsightsEndpoint: REACT_APP_INSIGHTS_ENDPOINT,
};
