import { ReactNode, useEffect, useRef } from 'react';
import styled from 'styled-components';
import theme from 'theme';

import CloseIcon from 'assets/icons/CloseIcon';
import useEscapeClick from 'hooks/useEscapeClick';
import useOutsideClick from 'hooks/useOutsideClick';

type ActionModalProps = {
  children: ReactNode;
  closeModalWindow: () => void;
};

const ActionModal = ({ children, closeModalWindow }: ActionModalProps) => {
  const modalWindowRef = useRef<HTMLDivElement>(null);

  useEscapeClick(modalWindowRef, closeModalWindow);
  useOutsideClick(modalWindowRef, closeModalWindow);

  useEffect(() => {
    document.body.style.overflowY = 'hidden';

    return () => {
      document.body.style.overflowY = 'auto';
    };
  }, []);

  return (
    <Container>
      <Content id="action_window" ref={modalWindowRef}>
        <CloseIconWrapper onClick={closeModalWindow}>
          <CloseIcon color={theme.colors.funtainBlue} prefix="modal" />
        </CloseIconWrapper>
        {children}
      </Content>
    </Container>
  );
};

const Container = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 20;
`;

const Content = styled.div`
  position: relative;
  top: -30px;
  width: 97%;
  height: 85%;
  max-width: 1326px;
  max-height: 790px;
  display: flex;
  justify-content: center;
  background-color: rgb(65, 78, 114);
  border-radius: 5px;

  @media (max-height: 850px) {
    top: 0;
  }
`;

const CloseIconWrapper = styled.div`
  position: absolute;
  top: 30px;
  cursor: pointer;
  right: 20px;
`;

export default ActionModal;
