import styled from 'styled-components';

export const LogoMark = styled.span<{
  colored?: boolean;
}>`
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 14px;
  height: 19px;
  color: ${({ theme, colored }) =>
    colored ? theme.colors.primary : theme.colors.white};
`;
