import { ComponentPropsWithoutRef, forwardRef, Ref } from 'react';
import styled from 'styled-components';

type FormInputProps = ComponentPropsWithoutRef<'input'> & {
  hide?: boolean;
};

const FormInput = (
  { hide, ...props }: FormInputProps,
  ref?: Ref<HTMLInputElement>
) => {
  return <Input ref={ref} {...props} hide={hide} maxLength={45} />;
};

const Input = styled.input<{ error?: boolean; hide?: boolean }>`
  letter-spacing: ${({ hide }) => (hide ? '3.4px' : 'normal')};
  width: 100%;
  height: 100%;
  font-size: 16px;
  outline: none;
  border: none;
  font-weight: 400;
  border-radius: 5px;
  background: none;
  padding: 6px 0 0;
  -webkit-text-fill-color: ${({ hide, theme }) =>
    hide ? 'white' : theme.colors.funtainBlue} !important;
`;

export default forwardRef(FormInput);
