import config from 'config';

import { axiosClient } from 'axiosClient/axiosClient';

const getAuthUserData = async (email: string, password: string) => {
  const authUserData = await axiosClient
    .post(`/api/v${config.apiVersion}/Token/PasswordToken`, {
      password,
      email,
    })
    .then((response) => response.data.payload);

  return authUserData;
};

const getUserRolesAndExtraData = async (
  token: string,
  refreshToken: string
) => {
  const roles = await axiosClient
    .get(`/api/v${config.apiVersion}/Account/UserRoles`, {
      headers: {
        authorization: `Bearer ${token}`,
      },
    })
    .then((response) => response.data.payload);

  return { ...roles, token, refreshToken };
};

export const checkUserValidity = async (email: string, password: string) => {
  return getAuthUserData(email, password)
    .then((response) => {
      return getUserRolesAndExtraData(response.token, response.refreshToken);
    })
    .catch((error) => {
      return error.response.data.payload;
    });
};

export const refreshRequest = ({
  accessToken,
  refreshToken,
}: {
  accessToken?: string;
  refreshToken?: string;
}): Promise<{ token: string; refreshToken: string }> =>
  axiosClient
    .post(
      `/api/v${config.apiVersion}/Token/RefreshToken`,
      {
        token: accessToken,
        refreshToken: refreshToken,
      },
      {
        headers: {
          authorization: `Bearer ${accessToken}`,
        },
      }
    )
    .then((response) => response.data.payload);
