const HideEyeIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    width="23.511"
    height="21.511"
    viewBox="0 0 23.511 21.511"
  >
    <defs>
      <clipPath id="clip-path">
        <path
          id="Path_8736"
          data-name="Path 8736"
          d="M23.2-12.451a20.954,20.954,0,0,0-4.3-3.6l4-3.995a1,1,0,0,0,.273-.973,1,1,0,0,0-.714-.714,1,1,0,0,0-.973.273l-.018.018a.737.737,0,0,1-.173.291l-19.5,19.5c-.008.007-.018.009-.026.017a1,1,0,0,0,.37,1.212A1,1,0,0,0,3.4-.546L7.546-4.692a11.26,11.26,0,0,0,4.31.939h.3c4.256,0,8.489-2.984,11.051-5.8A2.171,2.171,0,0,0,23.2-12.451ZM16.313-9.73a4.581,4.581,0,0,1-3,3.028,4.3,4.3,0,0,1-3.1-.19.253.253,0,0,1-.139-.186.253.253,0,0,1,.071-.221l5.56-5.559a.252.252,0,0,1,.221-.072.252.252,0,0,1,.186.139,4.3,4.3,0,0,1,.2,3.061Z"
          fill="#65b0c9"
        />
      </clipPath>
      <clipPath id="clip-path-2">
        <path
          id="Path_8735"
          data-name="Path 8735"
          d="M-1048,684H392V-516H-1048Z"
          fill="#65b0c9"
        />
      </clipPath>
      <clipPath id="clip-path-3">
        <path
          id="Path_8739"
          data-name="Path 8739"
          d="M7.615-9.6a.244.244,0,0,0,.061-.24A4.315,4.315,0,0,1,7.5-11,4.5,4.5,0,0,1,12-15.5a4.276,4.276,0,0,1,1.16.173.244.244,0,0,0,.24-.062l1.941-1.942a.254.254,0,0,0,.068-.238.254.254,0,0,0-.168-.183A10.413,10.413,0,0,0,12-18.25c-4.3-.058-8.6,2.95-11.187,5.8a2.15,2.15,0,0,0-.007,2.9,21.209,21.209,0,0,0,3.438,3.03A.256.256,0,0,0,4.57-6.55Z"
          fill="#65b0c9"
        />
      </clipPath>
    </defs>
    <g
      id="Group_10938"
      data-name="Group 10938"
      transform="translate(-0.247 21.765)"
    >
      <g id="Group_10935" data-name="Group 10935" clipPath="url(#clip-path)">
        <g
          id="Group_10934"
          data-name="Group 10934"
          clipPath="url(#clip-path-2)"
        >
          <path
            id="Path_8734"
            data-name="Path 8734"
            d="M-3.306-26.765H28.758V4.746H-3.306Z"
            fill="#65b0c9"
          />
        </g>
      </g>
      <g id="Group_10937" data-name="Group 10937" clipPath="url(#clip-path-3)">
        <g
          id="Group_10936"
          data-name="Group 10936"
          clipPath="url(#clip-path-2)"
        >
          <path
            id="Path_8737"
            data-name="Path 8737"
            d="M-4.753-23.251H20.416V-1.476H-4.753Z"
            fill="#65b0c9"
          />
        </g>
      </g>
    </g>
  </svg>
);

export default HideEyeIcon;
