import React, { ReactNode, useEffect, useState } from 'react';

import {
  AppInsightsContext,
  AppInsightsErrorBoundary,
  ReactPlugin,
} from '@microsoft/applicationinsights-react-js';
import config from 'config/index';
import { appInsights, initialise, reactPlugin } from 'services/AppInsights';

const AppInsightsContextProvider = ({ children }: { children: ReactNode }) => {
  const [isInitialised, setInitialised] = useState(false);

  const initialiseAppInsight = () => {
    const instrumentationKey = config.appInsightsKey;

    if (!instrumentationKey || !!appInsights) {
      return;
    }

    initialise(instrumentationKey);
    setInitialised(true);
  };

  initialiseAppInsight();

  useEffect(() => {
    if (!isInitialised) {
      return;
    }
  }, [isInitialised]);

  return (
    <AppInsightsErrorBoundary
      appInsights={reactPlugin as ReactPlugin}
      onError={() => <></>}
    >
      <AppInsightsContext.Provider value={reactPlugin as ReactPlugin}>
        {children}
      </AppInsightsContext.Provider>
    </AppInsightsErrorBoundary>
  );
};

export default AppInsightsContextProvider;
