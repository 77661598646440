import { useContext } from 'react';
import styled from 'styled-components';

import SignOutIcon from 'assets/icons/SignOutIcon';
import { AuthContext } from 'modules/auth/context/AuthContext';

const SignOutButton = () => {
  const { logout } = useContext(AuthContext);

  const routeChange = () => {
    logout();
  };

  return (
    <Container onClick={routeChange}>
      <Text>sign out</Text>
      <SignOutIcon />
    </Container>
  );
};

const Container = styled.button`
  margin-left: auto;
  display: flex;
  align-items: center;
  cursor: pointer;
  outline: none;
  border: none;
  padding: 0;
  background: none;
  margin-bottom: 6px;

  svg {
    margin-left: 10px;
  }
`;

const Text = styled.p`
  font-weight: 700;
  font-size: 12px;
  color: ${({ theme }) => theme.colors.funtainBlue};
  text-transform: uppercase;
  padding-top: 6px;
  letter-spacing: -0.4px;
`;

export default SignOutButton;
