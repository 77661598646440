import styled from 'styled-components';
import theme from 'theme';

import ChatIcon from 'assets/icons/ChatIcon';
import DefaultButton from 'components/buttons/DefaultButton';

type InstructionProps = {
  openModalWindow: () => void;
};

const Instruction = ({ openModalWindow }: InstructionProps) => {
  return (
    <>
      <IntroInfo>
        <span>0</span> results
      </IntroInfo>
      <Wrapper>
        <ChatIcon />
        <Title>Add your introductions</Title>
        <TextWrapper>
          <Text>
            Submitted introductions are matched against our client database & if
            your lead is the first record, it is assigned to you.
          </Text>
        </TextWrapper>
        <DefaultButton
          isSubmit
          isActive
          withShadow
          borderSize="md"
          buttonSize="md"
          text="Get started"
          textColor={theme.colors.white}
          buttonColor={theme.colors.funtainBlue}
          clickHandler={openModalWindow}
        />
      </Wrapper>
    </>
  );
};

const IntroInfo = styled.div`
  padding-top: 87px;
  font-weight: 400;
  font-size: 16px;
  margin-left: 18px;

  span {
    font-weight: 700;
    font-size: 16px;
  }
`;

const Wrapper = styled.div`
  position: relative;
  width: 100%;
  box-shadow: 0 5px 40px rgb(0 0 0 / 20%);
  background-color: ${({ theme }) => theme.colors.white};
  border-radius: 5px;
  height: 540px;
  margin-top: 16px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 146px;
  box-sizing: border-box;
`;

const Title = styled.p`
  font-size: 30px;
  margin-top: 20px;
  margin-left: -13px;
  letter-spacing: -0.4px;
`;

const TextWrapper = styled.div`
  max-width: 606px;
  margin-top: 8px;
  margin-bottom: 30px;
`;

const Text = styled.p`
  font-weight: 300;
  font-size: 20px;
  text-align: center;
  line-height: 1.5;
`;

export default Instruction;
