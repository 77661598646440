const SECONDS_TO_MINUTES_CONVERTER = 60;
const SECOND = 1000;
const MINUTES = 25;

export enum Token {
  AccessToken = 'ACCESS_TOKEN',
  RefreshToken = 'REFRESH_TOKEN',
}

export const TOKEN_ASSIGNMENT_TIME = 'TOKEN_ASSIGNMENT_TIME';

export const ACCESS_TOKEN_VALID_TIME =
  MINUTES * SECONDS_TO_MINUTES_CONVERTER * SECOND;
