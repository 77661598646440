import { debounce } from 'lodash';
import {
  createContext,
  ReactNode,
  useCallback,
  useEffect,
  useState,
} from 'react';
import { useMutation } from 'react-query';
import { useNavigate } from 'react-router-dom';

import {
  ACCESS_TOKEN_VALID_TIME,
  Token,
  TOKEN_ASSIGNMENT_TIME,
} from '../models';
import { refreshRequest } from '../service/authService';

type AuthContextType = {
  accessToken?: string;
  isAuthenticated?: boolean;
  tokenUpdateComplete?: boolean;
  accessTokenAssignmentTime: number;
  logout: () => void;
  setTokens: (accessToken: string, refreshToken: string) => void;
  updateTokens: (
    accessToken: string,
    refreshToken: string,
    tokenAssignmentTime: number
  ) => void;
};

const initialValue: AuthContextType = {
  accessTokenAssignmentTime: 0,
  setTokens: () => null,
  logout: () => null,
  updateTokens: () => null,
};

export const AuthContext = createContext<AuthContextType>(initialValue);

type AuthContextProviderProps = {
  children: ReactNode;
};

const AuthContextProvider = ({ children }: AuthContextProviderProps) => {
  const navigate = useNavigate();
  const [accessTokenAssignmentTime, setAccessTokenAssignmentTime] = useState(0);
  const [accessToken, setAccessToken] = useState<string>();
  const [refreshToken, setRefreshToken] = useState<string>();
  const [isAbleToRefetch, setIsAbleToRefetch] = useState(true);
  const [tokenUpdateComplete, setTokenUpdateComplete] = useState(false);
  const [isFirstRequestDone, setIsFirstRequestDone] = useState(false);

  const setTokens = useCallback((accessToken: string, refreshToken: string) => {
    const tokenAssignmentTime = Date.now();

    setIsAbleToRefetch(true);
    setAccessToken(accessToken);
    setRefreshToken(refreshToken);
    setAccessTokenAssignmentTime(tokenAssignmentTime);

    localStorage.setItem(Token.AccessToken, accessToken);
    localStorage.setItem(Token.RefreshToken, refreshToken);
    localStorage.setItem(TOKEN_ASSIGNMENT_TIME, String(tokenAssignmentTime));
  }, []);

  const logout = useCallback(() => {
    setAccessToken(undefined);
    setRefreshToken(undefined);

    localStorage.removeItem(Token.AccessToken);
    localStorage.removeItem(Token.RefreshToken);
    localStorage.removeItem(TOKEN_ASSIGNMENT_TIME);
    navigate('/login');
  }, [navigate]);

  const updateTokens = useCallback(
    (
      accessToken: string,
      refreshToken: string,
      tokenAssignmentTime: number
    ) => {
      setAccessToken(accessToken);
      setRefreshToken(refreshToken);
      setAccessTokenAssignmentTime(tokenAssignmentTime);
    },
    []
  );

  const refreshQuery = useMutation(refreshRequest, {
    onSuccess: ({ token, refreshToken }) => setTokens(token, refreshToken),
    onError: logout,
    onSettled: () => {
      if (!tokenUpdateComplete) {
        setTokenUpdateComplete(true);
      }
    },
  });

  useEffect(() => {
    const refetchTime =
      ACCESS_TOKEN_VALID_TIME - (Date.now() - accessTokenAssignmentTime);

    const refetchAccessToken = debounce(() => {
      if (
        accessToken &&
        refreshToken &&
        isAbleToRefetch &&
        tokenUpdateComplete
      ) {
        setIsAbleToRefetch(false);
        refreshQuery.mutate({ accessToken, refreshToken });
      }
    }, refetchTime);

    if (!accessToken || !refreshToken || !tokenUpdateComplete) {
      refetchAccessToken.cancel();
    } else {
      refetchAccessToken();
    }

    return () => refetchAccessToken.cancel();
  }, [
    accessToken,
    accessTokenAssignmentTime,
    isAbleToRefetch,
    logout,
    refreshQuery,
    refreshToken,
    tokenUpdateComplete,
  ]);

  useEffect(() => {
    if (!tokenUpdateComplete) {
      const accessTokenAssignmentTime = Number(
        localStorage.getItem(TOKEN_ASSIGNMENT_TIME)
      );
      const accessToken = localStorage.getItem(Token.AccessToken);
      const refreshToken = localStorage.getItem(Token.RefreshToken);

      if (accessToken && refreshToken && accessTokenAssignmentTime) {
        const currentTime = Date.now();

        if (
          currentTime - accessTokenAssignmentTime <=
          ACCESS_TOKEN_VALID_TIME
        ) {
          updateTokens(accessToken, refreshToken, accessTokenAssignmentTime);
          setTokenUpdateComplete(true);
        } else if (!isFirstRequestDone) {
          setIsFirstRequestDone(true);
          refreshQuery.mutate({
            accessToken,
            refreshToken,
          });
        }
      } else {
        setTokenUpdateComplete(true);
      }
    }
  }, [isFirstRequestDone, refreshQuery, tokenUpdateComplete, updateTokens]);

  return (
    <AuthContext.Provider
      value={{
        accessToken,
        tokenUpdateComplete,
        isAuthenticated: Boolean(accessToken),
        setTokens,
        logout,
        updateTokens,
        accessTokenAssignmentTime,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export default AuthContextProvider;
