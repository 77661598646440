import { ComponentPropsWithoutRef, useEffect } from 'react';
import styled from 'styled-components';

type FormLabelProps = ComponentPropsWithoutRef<'label'> & {
  active: boolean;
  coloured?: boolean;
  setDelay?: (value: boolean) => void;
};

const TIMOUT_DELAY = 700;

const FormLabel = ({
  active,
  coloured,
  setDelay,
  ...props
}: FormLabelProps) => {
  useEffect(() => {
    if (setDelay) {
      setTimeout(() => {
        setDelay(true);
      }, TIMOUT_DELAY);
    }
  }, [setDelay]);

  return (
    <Label {...props} active={active} coloured={coloured}>
      {props.children}
    </Label>
  );
};

const Label = styled.label<{
  active: boolean;
  coloured?: boolean;
}>`
  color: ${({ theme, coloured, active }) =>
    coloured && !active ? theme.colors.funtainBlue : theme.colors.darkBlue};
  font-size: ${({ active }) => (active ? '11px' : '16px')};
  line-height: ${({ active }) => (active ? '13px' : '18px')};
  font-weight: 400;
  position: absolute;
  transition: 0.5s;
  top: ${({ active }) => (active ? '6px' : '20px')};
  cursor: ${({ active }) => (active ? 'default' : 'text')};
`;

export default FormLabel;
