import styled, { css } from 'styled-components';

export const FormContainer = styled.form`
  position: relative;
  align-items: center;
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const InputContainer = styled.div<{
  error?: boolean;
  disabled?: boolean;
  withDifferentBorderColor?: boolean;
  emptyInputs?: number;
}>`
  position: relative;
  height: 50px;
  margin-bottom: 33px;
  border-bottom: 1px solid
    ${({ error, theme }) =>
      error ? theme.colors.lightRedV1 : theme.colors.funtainBlue};
  box-sizing: border-box;
  width: 100%;
  ${({ error, withDifferentBorderColor }) =>
    withDifferentBorderColor &&
    css`
      &:nth-last-child(-n + 2) {
        border-bottom: 1px solid
          ${({ theme }) =>
            error ? theme.colors.lightRedV1 : theme.colors.darkBlue};
      }
    `}
  ${({ disabled, emptyInputs }) =>
    disabled &&
    css`
      border-bottom: 1px solid ${({ theme }) => theme.colors.darkBlue};
      opacity: 0.4;
      user-select: text;
      pointer-events: none;
      &:nth-last-child(-n + ${emptyInputs || 2}) {
        border-bottom: none;
      }
    `};

  @media (max-height: 769px) {
    margin-bottom: 20px;
  }
`;

export const ErrorText = styled.div<{ error?: boolean }>`
  position: relative;
  display: flex;
  align-items: center;
  color: ${({ theme }) => theme.colors.primary} !important;
  visibility: ${({ error }) => (error ? 'visible' : 'hidden')};
  font-weight: 400;
  font-size: 12px;

  svg {
    margin-right: 5px;
  }
`;

export const ContinueButtonContainer = styled.div`
  margin-top: 73px;
`;

export const LoaderContainer = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const AsteriskText = styled.span<{ hidePassword: boolean }>`
  padding-top: 5px;
  letter-spacing: 3.5px;
  font-size: 18px;
  height: 55%;
  background: white;
  position: absolute;
  display: ${({ hidePassword }) => (hidePassword ? 'block' : 'none')};
  color: ${({ theme }) => theme.colors.funtainBlue};
  margin-top: 20px;
`;

export const EyeButton = styled.div<{ hidePassword?: boolean }>`
  position: absolute;
  right: -2px;
  display: block;
  cursor: pointer;
  top: ${({ hidePassword }) => (hidePassword ? '47%' : '50%')};
`;
