import { Navigate, Route, Routes } from 'react-router-dom';

import Introduction from 'modules/introduction/pages/Introduction';

import PartnerLogin from '../auth/pages/PartnerLogin';

import { RoutesPath } from './models';
import PrivateRoute from './PrivateRoute';

export default (
  <div>
    <Routes>
      <Route path={RoutesPath.PartnerLogin} element={<PartnerLogin />} />
      <Route
        path={RoutesPath.Introduction}
        element={
          <PrivateRoute>
            <Introduction />
          </PrivateRoute>
        }
      />
      <Route
        path="*"
        element={<Navigate to={RoutesPath.PartnerLogin} replace />}
      />
    </Routes>
  </div>
);
